// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{background:#f8f8f8}.u6o_C{display:flex;max-width:1200px;max-width:var(--home-width);margin:0 auto;align-items:center;justify-content:space-between;min-height:calc(100vh - 150px);padding:16px}.z7owO{flex-grow:1;display:flex;flex-direction:column;max-width:530px}.JyhFg{text-align:center;flex:1;margin-left:50px;display:flex;justify-content:space-between;flex-direction:column}.qU07l{color:#203449;color:var(--body-text-color);font-weight:700;font-size:3rem;line-height:1.2;margin-bottom:0;margin-top:0}.oH9wj{font-size:25px;font-size:var(--size-typo-xl);color:#2D455D;color:var(--color-2);font-weight:400;line-height:2.4rem;letter-spacing:-.05px;margin-top:32px}.b3Xdj{margin-top:40px}.nrH2X{margin-top:30px;font-size:1.2rem;font-size:var(--big-size);width:-moz-fit-content;width:fit-content;color:#506cf0;color:var(--primary-color);border-radius:3px;font-weight:500;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;font-family:var(--system-font)}.LExgj svg{width:100%;height:100%}@media (max-width: 767.98px){.LExgj{display:none;}}.m7HW9{margin-top:30px;opacity:.8}.mN7z4{position:absolute;z-index:-1}@media (max-width: 767.98px){.u6o_C{flex-wrap:wrap;align-items:baseline;padding:50px 20px 20px}.z7owO{order:-1;flex-basis:100%;max-width:100%;align-items:center}.JyhFg{order:1;flex-basis:100%;padding-top:0;text-align:center;margin-left:0}.LExgj svg{width:100%;height:auto}.qU07l{font-size:2.125rem}.oH9wj{max-width:100%;font-size:1.4rem}.b3Xdj{margin-top:30px;width:100%;}.b3Xdj>*{width:100%}.nrH2X{display:none}}.Lo3Gv{display:block;font-size:1.15rem;font-size:var(--medium-size);cursor:inherit;margin-bottom:40px;opacity:.7;text-transform:uppercase;letter-spacing:2px}.MIGqx{width:100%;height:100%;max-width:411px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `u6o_C`,
	"content": `z7owO`,
	"images": `JyhFg`,
	"title": `qU07l`,
	"text": `oH9wj`,
	"buttons": `b3Xdj`,
	"hint": `nrH2X`,
	"icon": `LExgj`,
	"payments": `m7HW9`,
	"bg": `mN7z4`,
	"payoutLabel": `Lo3Gv`,
	"shrink": `MIGqx`
};
export default ___CSS_LOADER_EXPORT___;
