import React, { useMemo } from 'react';
import withStyles from 'utils/withStyles';
import { useTranslation } from 'react-i18next';

import Guy from 'assets/guy_color.svg';
import Payments from 'assets/payment-systems.svg';
import NextButton from 'components/form/NextButton/NextButton';
import { Helmet } from 'react-helmet-async';
import isInitialRender from 'utils/initialRender';
import s from './Home.css';
import Figures from './Figures';
import useUser from '../../../hooks/useUser';
import PageTitle from '../PageTitle/PageTitle';
import IsomorphicRedirect from '../IsomorphicRedirect/IsomorphicRedirect';

// I wish I used styled-components here
// Todo using SSR we can control output and therefore prevent body from entering without animation
function BodyStyles() {
  const styles = `
@keyframes inside {
  0% {
    opacity: 0.6;
    transform: scale3d(0.8, 0.8, 1);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@media (min-width: 760px) {
  body {
    transform: scale3d(0.8, 0.8, 1);
    transform-style: preserve-3d;
  
    animation: inside 1.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }
}
  `;
  return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}

function Home() {
  const isInitial = useMemo(() => isInitialRender(), []);
  const { t } = useTranslation();

  const currentUser = useUser();

  if (currentUser) {
    return <IsomorphicRedirect to="/tasks" />;
  }

  return (
    <div className={s.container}>
      <Helmet>
        <link rel="canonical" href="https://youthink.io" />
      </Helmet>
      <PageTitle>{t('Интернет опросы')}</PageTitle>
      {isInitial && <BodyStyles />}
      <Figures />
      <div className={s.content}>
        <h1 className={s.title}>{t('Заполняйте опросы и получайте бонусы')}</h1>
        <h2 className={s.text}>
          {t(
            'Ваше мнение помогает брендам понять, что вы думаете о них!',
          )}
        </h2>
        <div className={s.buttons}>
          <NextButton
            className={s.button}
            to="/auth/signup"
            buttonStyle={NextButton.Types.Primary}
          >
            {t('Зарегистрироваться')}
          </NextButton>
        </div>
        <div className={s.hint}>{t('Получите бонус за первую анкету')}</div>
      </div>
      <div className={s.images}>
        <div className={s.icon}>
          <Guy />
        </div>
      </div>
    </div>
  );
}

export default withStyles(s)(Home);
